
import { defineComponent, toRefs, ref, computed, reactive, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import editButton from '@/components/editButton/index.vue'
import { useRouter } from 'vue-router'
import {
  RegFillClass,
  FillClass,
} from '@/model/competitiveAnalysis/fillInformation/fillInformation'
import { message } from 'ant-design-vue'
import productApi from '@/axios/api/product'
import { useStore } from 'vuex'
import Data from '@/model/common/Data'
import yTable from '@/components/yTable/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
import Drawer from '@/components/Drawer/Drawer.vue'
export default defineComponent({
  name: 'peerData',
  components: {
    listLayout,
    editButton,
    yTable,
    FormItem,
    Drawer,
  },
  setup() {
    // const isShowDrawer = ref(false)

    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    const peerTable = ref()
    //获取路由
    let router = useRouter()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false,
      selectFrom: new RegFillClass(),
      dataSource: [] as FillClass[],
      columns: [
        {
          title: '规格型号',
          dataIndex: 'productModel',
          width: 200,
          key: 'productModel',
          resizable: true,
        },
        {
          title: '物料编号',
          dataIndex: 'productCode',
          width: 130,
          key: 'productCode',
          resizable: true,
        },
        {
          title: '物料名称',
          dataIndex: 'productName',
          width: 180,
          key: 'productName',
          resizable: true,
        },
        {
          title: '所属部门',
          dataIndex: 'deptName',
          width: 150,
          key: 'deptName',
          resizable: true,
        },
        {
          title: '产品经理',
          dataIndex: 'productManager',
          width: 95,
          key: 'productManager',
          resizable: true,
        },
        {
          title: '填报状态',
          dataIndex: 'reportStatus',
          width: 95,
          key: 'reportStatus',
          resizable: true,
        },
        {
          title: '填报人（最后）',
          dataIndex: 'informant',
          width: 95,
          key: 'informant',
          resizable: true,
        },
        {
          title: '填报时间（最后）',
          dataIndex: 'reportingTime',
          width: 130,
          key: 'reportingTime',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 130,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'productModel',
          placeholder: '请输入怡合达规格型号',
          label: '怡合达规格型号',
        },
        {
          type: 'subInput',
          prop: 'productName',
          placeholder: '请输入物料名称',
          label: '物料名称',
        },
        {
          type: 'subInput',
          prop: 'productCode',
          placeholder: '请输入物料编号',
          label: '物料编号',
        },
        {
          type: 'subInput',
          prop: 'productManager',
          placeholder: '请输入产品经理',
          label: '产品经理',
        },
        {
          type: 'slot',
          prop: 'deptCode',
          placeholder: '请选择所属部门',
          label: '所属部门:',
        },
      ] as FormItemListType<FillClass>,
      reportStatus: {
        0: '未填报',
        1: '部分填报',
        2: '全部填报',
      } as Data,
      optionList: [] as Data[],
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      productApi
        .ProductList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //跳转至账号设置页面
    const jump = (type: string, id: string, reportStatus?: number) => {
      if (type === 'add') {
        if (reportStatus === 2) {
          message.info('该数据已经填报完成,无需填报。')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/addFill/${id}`)
      } else if (type === 'update') {
        if (reportStatus === 0) {
          message.info('该数据未填报!')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/updateFill/${id}`)
      } else if (type === 'detail') {
        if (reportStatus === 0) {
          message.info('该数据未填报!')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/Detail/${id}`)
      }
    }
    //新增编辑按钮
    const addUpdateFill = (type: string) => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择一项数据！')
        return
      }
      let data = pageData.dataSource.find(
        (item) => item.productCode === pageData.selectedRowKeys[0]
      )
      console.log(data?.reportStatus)

      jump(type, pageData.selectedRowKeys[0], data?.reportStatus as number)
    }

    //搜索
    const search = () => {
      selectProduct()
    }
    //获取产品部门选项
    productApi
      .GetProductDept()
      .then((res) => {
        pageData.optionList = res.map((item) => {
          return {
            label: item.deptName,
            value: item.deptCode,
          }
        })
      })
      .catch((err) => {
        message.error(err)
      })
    //重置
    const reset = () => {
      pageData.selectFrom.data = new FillClass()
      selectProduct()
    }
    return {
      addUpdateFill,
      ...toRefs(pageData),
      peerTable,
      search,
      jump,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      updateColumns,
    }
  },
})
