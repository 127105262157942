/**
 * 填报信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
export class FillClass {
  id: string | undefined
  productModel: string | undefined //规格型号",
  productCode: string | undefined //物料编号",
  productName: string | undefined //物料名称",
  deptName: string | undefined //所属部门",
  deptCode: string | undefined //部门编号",
  productManager: string | undefined //产品经理",
  reportStatus: number | undefined //填报状态 0：未填报；1：部分填报；2：全部填报",
  informant: string | undefined //填报人（最后）",
  reportingTime: string | undefined //填报时间（最后）"
  productCategory: string | undefined //产品类别",
  productImgUrl: string //产品图片地址"
  constructor() {
    this.id = undefined
    this.productModel = undefined
    this.productCode = undefined
    this.productName = undefined
    this.deptName = undefined
    this.deptCode = undefined
    this.productManager = undefined
    this.reportStatus = undefined
    this.informant = undefined
    this.reportingTime = undefined
    this.productCategory = undefined
    this.productImgUrl = ''
  }
}
export class RegFillClass extends ReqPage {
  data: FillClass
  constructor() {
    super()
    this.data = new FillClass()
  }
}

export interface ResFillClass extends ReqPage {
  data: FillClass[]
}
